/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { KTSVG } from '../../../_metronic/helpers'

type Props = {
  className?: string
  svgIconPath?: string
  titleClass?: string
  descriptionClass?: string
  iconClass?: string
  title?: string
  description?: string
  url?: string
}
const IconLinkWidget = (props: Props) => {
  const {className, svgIconPath, titleClass, descriptionClass, iconClass, title, description, url} = props
  return (
    <a href={url || '#'} target={url ? "_blank" : ''} className={clsx('card', className)}>
      <div className='card-body d-flex flex-column justify-content-between text-center'>
        <KTSVG
            path={svgIconPath || ''}
            className={clsx(iconClass, 'svg-icon svg-icon-3x')}
        />
        <div className='d-flex flex-column'>
          <div className={clsx(titleClass, 'fw-bold fs-3 mb-0 mt-5')}>{title}</div>
          <div className={clsx(descriptionClass, 'fw-semibold fs-6')}>{description}</div>
        </div>
      </div>
    </a>
  )
}

export {IconLinkWidget}
