/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import { useThemeMode } from '../../../_metronic/partials'
import { getCSSVariableValue } from '../../../_metronic/assets/ts/_utils'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
}

const PropertyChartWidget: React.FC<Props> = ({className, chartColor, chartHeight}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, chartOptions(chartColor, chartHeight))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode])

  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body p-0 d-flex justify-content-between flex-column overflow-hidden'>
        {/* begin::Hidden */}
        <div className='d-flex flex-stack flex-wrap flex-grow-1 px-9 pt-9 pb-3'>
          <div className='me-2'>
            <span className='fw-bold text-gray-800 d-block fs-3'>Property Value</span>

            <span className='text-gray-400 fw-semibold'>2023</span>
          </div>

          <div className={`fw-bold fs-3 text-${chartColor}`}>+45%</div>
        </div>
        {/* end::Hidden */}

        {/* begin::Chart */}
        <div ref={chartRef} className='mixed-widget-10-chart'></div>
        {/* end::Chart */}
      </div>
    </div>
  )
}

const chartOptions = (chartColor: string, chartHeight: string): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const secondaryColor = getCSSVariableValue('--bs-gray-300')
  const baseColor = getCSSVariableValue('--bs-' + chartColor)

  return {
    series: [{
        name: 'Best',
        type: 'line',
        data: [0, -1000, -3000, -5000, -8000, -5000, -2000, 1000, 5000, 10000, 15000, 20000],
        color: '#30E199'
    }, {
        name: 'Real',
        type: 'line',
        data: [0, -2000, -4000, -8000, -10000, -8000, -5000, -2000, 2000, 5000, 10000, 15000],
        color: '#30B7E1'
    }, {
        name: 'Worst',
        type: 'line',
        data: [0, -3000, -5000, -9000, -11000, -9000, -7000, -5000, -3000, -1000, 1000, 3000],
        color: '#E13030'
    }],
    chart: {
        fontFamily: 'inherit',
        height: chartHeight,
        type: 'line',
        toolbar: {
            show: false,
        },
        zoom: {
            enabled: false,
        },
        sparkline: {
            enabled: true,
        },
    },
    plotOptions: {},
    legend: {
        show: false,
    },
    dataLabels: {
        enabled: false,
        style: {
            colors: ['#30E199', '#30B7E1', '#E13030'],
        }
    },
    markers: {
        colors: ['#30E199', '#30B7E1', '#E13030'],
    },
    fill: {
        colors: ['#30E199', '#30B7E1', '#E13030'],
        type: 'solid',
        opacity: 1,
    },
    stroke: {
        curve: 'smooth',
        show: true,
        width: 3,
        colors: ['#30E199', '#30B7E1', '#E13030'],
    },
    xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: ['#30E199', '#30B7E1', '#E13030'],
            fontSize: '12px',
          },
        },
      },
    yaxis: {
        title: {
            text: '€',
        }
    },
    states: {
        normal: {
            filter: {
                type: 'none',
                value: 0,
            },
        },
        hover: {
            filter: {
                type: 'none',
                value: 0,
            },
        },
        active: {
            allowMultipleDataPointsSelection: false,
            filter: {
                type: 'none',
                value: 0,
            },
        },
    },
    tooltip: {
        style: {
            fontSize: '12px',
        },
        y: {
            formatter: function (y) {
            if (typeof y !== "undefined") {
                return y.toFixed(0) + " €";
            }
            return y;
        
            }
        }
    }
  }
}

export {PropertyChartWidget}
