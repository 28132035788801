import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  MixedWidget10,
  MixedWidget11,
  TilesWidget5
} from '../../../_metronic/partials/widgets'
import {
  IconLinkWidget
} from '../../modules/widgets/IconLinkWidget'
import { PropertyChartWidget } from '../../modules/widgets/PropertyChartWidget'

const DashboardPage = () => (
  <>
    {/*<div className='row g-5 g-xl-8'>
      <div className='col-xl-8'>
        <div className='row gx-5 gx-xl-8 mb-5 mb-xl-8'>
          <div className='col-xl-6'>
            <div className='row gx-5 gx-xl-8'>
              <div className='col-xl-6'>
                <TilesWidget5
                  className='card-xxl-stretch bg-primary'
                  svgIcon='element-11'
                  titleClass='text-white'
                  descriptionClass='text-white'
                  iconClass='text-white'
                  title='790'
                  description='New Products'
                />
              </div>
              <div className='col-xl-6'>
                <TilesWidget5
                  className='card-xxl-stretch bg-body'
                  svgIcon='rocket'
                  titleClass='text-dark'
                  descriptionClass='text-muted'
                  iconClass='text-success'
                  title='8,600'
                  description='New Customers'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>*/}

    <div className="row gy-5 g-xl-8">
      <div className='col-xxl-2 gy-5'>
        <IconLinkWidget
          className='card-xxl-stretch bg-body'
          svgIconPath='/media/icons/duotune/logo/nextmode.svg'
          titleClass='text-dark'
          descriptionClass='text-muted'
          iconClass='text-success'
          title='Web'
          description='NextMode.eu'
          url='https://www.nextmode.eu'
        />
      </div>
      <div className="col-xxl-2 gy-5">
        <IconLinkWidget
          className='card-xxl-stretch bg-body'
          svgIconPath='/media/icons/duotune/logo/bitbucket.svg'
          titleClass='text-dark'
          descriptionClass='text-muted'
          iconClass='text-white'
          title='Bitbucket'
          description='Projects sources'
          url='https://bitbucket.org/CooderSK/workspace/projects/NEX'
        />
      </div>
      <div className='col-xxl-2 gy-5'>
        <IconLinkWidget
          className='card-xxl-stretch bg-body'
          svgIconPath='/media/icons/duotune/logo/google-drive.svg'
          titleClass='text-dark'
          descriptionClass='text-muted'
          iconClass='text-success'
          title='Google Drive'
          description='Cloud'
          url='https://drive.google.com/'
        />
      </div>
      <div className='col-xxl-2 gy-5'>
        <IconLinkWidget
          className='card-xxl-stretch bg-body'
          svgIconPath='/media/icons/duotune/logo/trello.svg'
          titleClass='text-dark'
          descriptionClass='text-muted'
          iconClass='text-success'
          title='Trello'
          description='Issue Management'
          url='https://trello.com/b/F6dEI249/nextmode-sro'
        />
      </div>
      <div className='col-xxl-2 gy-5'>
        <IconLinkWidget
          className='card-xxl-stretch bg-body'
          svgIconPath='/media/icons/duotune/logo/google-play.svg'
          titleClass='text-dark'
          descriptionClass='text-muted'
          iconClass='text-success'
          title='Google Play'
          description='Developer Console'
          url='https://play.google.com/console/u/1/developers/9153711240963466787/app/4974890544884948540/app-dashboard?timespan=thirtyDays'
        />
      </div>
      <div className='col-xxl-2 gy-5'>
        <IconLinkWidget
          className='card-xxl-stretch bg-body'
          svgIconPath='/media/icons/duotune/logo/youtube.svg'
          titleClass='text-dark'
          descriptionClass='text-muted'
          iconClass='text-success'
          title='YouTube'
          description='YouTube channel'
          url='https://www.youtube.com/@NextModeSro'
        />
      </div>
    </div>
    
    {/* begin::Row */}
    <div className='row gy-5 g-xl-8'>
      <div className='col-xxl-6'>
        <PropertyChartWidget
          className='card-xxl-stretch-50 mb-5 mb-xl-8'
          chartColor='primary'
          chartHeight='175px'
        />
        <MixedWidget11
          className='card-xxl-stretch-50 mb-5 mb-xl-8'
          chartColor='primary'
          chartHeight='175px'
        />
      </div>
      <div className='col-xxl-6'>
        <MixedWidget10
          className='card-xxl-stretch-50 mb-5 mb-xl-8'
          chartColor='primary'
          chartHeight='150px'
        />
        <MixedWidget11
          className='card-xxl-stretch-50 mb-5 mb-xl-8'
          chartColor='primary'
          chartHeight='175px'
        />
      </div>
    </div>
    {/* end::Row */}
  </>
)

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
